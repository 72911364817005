.page-view {
   flex: 1;
   padding-top: 20px;
   padding-bottom: 20px;
   background-color: var(--regular-background);
   position: relative;
}

.page-view-content {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   padding-bottom: 10px;
}

.page-view-not-found {
   flex: 1;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   text-align: center;
}

.page-view-not-found img {
   height: 250px;
   width: 250px;
   object-fit: cover;
}

.page-view-navigation {
   display: flex;
   /*position: absolute;*/
   bottom: 0px;
   z-index: 1;
   justify-content: center;
}

@media (max-width: 1000px) {
   .page-view-not-found {
      min-height: var(--min-height-content-mobile);
   }
   .page-view-navigation {
      width: calc(100vw - 40px);
   }
   .page-view-content {
      padding-bottom: 60px;
   }
}

@media (min-width: 1000px) {
   .page-view-not-found {
      min-height: var(--min-height-content);
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;
   }
   .page-view-navigation {
      width: var(--main-width);
   }
}
